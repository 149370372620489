import(/* webpackMode: "eager" */ "/var/www/vhosts/wazifame.com/httpdocs/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/wazifame.com/httpdocs/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/wazifame.com/httpdocs/src/app/(www)/(home)/_sections/HomeLazySections.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/wazifame.com/httpdocs/src/app/(www)/(home)/_sections/ImagesSlider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/wazifame.com/httpdocs/src/components/CvRedirect/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/wazifame.com/httpdocs/src/components/Home/CompaniesLinkArrow.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/wazifame.com/httpdocs/src/components/ui/Jobs/homeJobSearch.tsx");
