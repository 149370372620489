"use client";
import { getSession } from "next-auth/react";
import { useEffect, useState } from "react";
import { useRouter, useSearchParams } from "next/navigation";
import PageLoading from "@components/Loading/PageLoading";
import { toast } from "react-toastify";

const CVRedirect = () => {
    const searchParams = useSearchParams();
    const router = useRouter();
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("");

  useEffect(() => {
    const uploadCV = async () => {
      setLoadingMessage("Uploading CV, please wait...");
      setIsLoading(true);

      const cvId = localStorage.getItem("cvId") || searchParams.get("cvId");
      const session = await getSession();

      if (!session?.user?.id) {
        console.error("User is not authenticated", { session });
        toast.error("You need to log in to proceed.", { toastId: "user-auth-error" });
        router.push("/login");
        setIsLoading(false);
        return;
      }

      if (!cvId) {
        console.error("CV was not uploaded successfully", { cvId });
        toast.error("CV was not uploaded successfully. Please try again.", { toastId: "cv-upload-error" });
        setIsLoading(false);
        return;
      }

      try {
        const formData = new FormData();
        formData.append("userId", session.user.id);
        formData.append("cvId", cvId);

        const res = await fetch(
          `${process.env.NEXT_PUBLIC_API_URL}/user/cv/create`,
          { method: "PUT", body: formData }
        );

        if (res.ok) {
          const params = new URLSearchParams(searchParams.toString());
          params.delete("pendingCv");
          localStorage.removeItem("cvId");
          router.push("/candidate/profile/cv");
        } else {
          const errorData = await res.json();
          console.error("Failed to update CV", errorData);
          toast.error("There was an error updating your CV, please try again.", {
            toastId: "cv-update-error",
          });
        }
      } catch (error) {
        console.error("Error updating CV", error);
        toast.error("An unexpected error occurred while uploading your CV.", {
          toastId: "cv-upload-exception",
        });
      } finally {
        setIsLoading(false);
      }
    };

    const pendingCV = searchParams.get("pendingCv");
    const cvId = localStorage.getItem("cvId") || searchParams.get("cvId");
    if (pendingCV && cvId) {
      uploadCV();
    }
  }, [searchParams, router]);

  return (
    <>
      {isLoading && (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <PageLoading />
          <p>{loadingMessage}</p>
        </div>
      )}
    </>
  );
};

export default CVRedirect;
